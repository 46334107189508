import React from "react";
import styles from "./TopNav.module.scss";
import logo from "../../assets/tree-48.png";
import { Link } from "react-router-dom";

export default function TopNav() {
  return (
    <nav className={styles.container}>
      <ul>
        <li>
          <Link
            className={styles.biggest_wood}
            to="/"
          >
            <img
              src={logo}
              alt="tree logo"
            />
            BiggestWood
          </Link>
        </li>
        <li className={styles.gallery}>
          <Link
            className={styles.link}
            to="/gallery"
          >
            Gallery
          </Link>
        </li>
      </ul>
    </nav>
  );
}
