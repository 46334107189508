import React from "react";
import styles from "./Hero.module.scss";
// import Tree from "./assets/tree.jpg";

export default function Hero() {
  return (
    <div className={styles.container}>
      <p>We are hard at work</p>
    </div>
  );
}
