import styles from "./Footer.module.scss";

export default function Footer() {
  const now = new Date().getFullYear();
  return (
    <footer>
      <p>Coming soon</p>
      <p className={styles.copy_write}>&copy; {now} BiggestWood</p>
    </footer>
  );
}
