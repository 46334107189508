import { Outlet } from "react-router-dom";
import { ReactNode } from "react";

import TopNav from "../../containers/TopNav/TopNav";
import styles from "./Root.module.scss";
import Footer from "../../containers/Footer/Footer";

interface Props {
  children?: ReactNode;
}

export default function Root(props: Props) {
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <TopNav />
      </header>
      <Outlet />
      {props.children}
      <Footer />
    </div>
  );
}
