import React from "react";
import Hero from "./Hero/Hero";
import styles from "./Home.module.scss";

export default function Home() {
  return (
    <div className={styles.container}>
      <Hero />
      <div className={styles.content}>{/* <h1>Content</h1> */}</div>
    </div>
  );
}
