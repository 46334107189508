import React from "react";
import styles from "./Gallery.module.scss";
import balls from "../../assets/IMG_5181.jpeg";
import hug from "../../assets/tree-hug.jpg";

export default function Gallery() {
  return (
    <div className={styles.container}>
      <img
        src={balls}
        alt="ball sack tree"
      />
      <img
        src={hug}
        alt="tree hugger"
      />
    </div>
  );
}
