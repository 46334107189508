import { createBrowserRouter } from "react-router-dom";
import Root from "./pages/Root/Root";
import Gallery from "./pages/Gallery/Gallery";
import Error from "./pages/Error/Error";
import Home from "./pages/Home/Home";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: (
      <Root>
        <Error />
      </Root>
    ),
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "gallery",
        element: <Gallery />,
      },
      {
        path: "gallery",
        element: <Gallery />,
      },
    ],
  },
]);
